import { validatenull } from './validate'

//表单序列化
export const serialize = data => {
    let list = [];
    Object.keys(data).forEach(ele => {
        list.push(`${ele}=${data[ele]}`)
    })
    return list.join('&');
};
// 判断数据类型
export const getObjType = obj => {
    var toString = Object.prototype.toString;
    var map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object'
    };
    if (obj instanceof Element) {
        return 'element';
    }
    return map[toString.call(obj)];
};
/**
 * 对象深拷贝
 */
export const deepClone = data => {
    var type = getObjType(data);
    var obj;
    if (type === 'array') {
        obj = [];
    } else if (type === 'object') {
        obj = {};
    } else {
        //不再具有下一层次
        return data;
    }
    if (type === 'array') {
        for (var i = 0, len = data.length; i < len; i++) {
            obj.push(deepClone(data[i]));
        }
    } else if (type === 'object') {
        for (var key in data) {
            obj[key] = deepClone(data[key]);
        }
    }
    return obj;
};

/**
 * 递归寻找子类的父类
 */

export const findParent = (menu, id) => {
    for (let i = 0; i < menu.length; i++) {
        if (menu[i].children.length != 0) {
            for (let j = 0; j < menu[i].children.length; j++) {
                if (menu[i].children[j].id == id) {
                    return menu[i];
                } else {
                    if (menu[i].children[j].children.length != 0) {
                        return findParent(menu[i].children[j].children, id);
                    }
                }
            }
        }
    }
};
/**
 * 通过id递归寻找子类的name
 */

export const findChildrenName = (menu, id, props) => {
    let { label = 'label', value = 'value', children = 'children' } = { label: props.label, value: props.value, children: props.children };
    var name;
    var find = function (menu, id) {
        for (let i = 0; i < menu.length; i++) {
            if (menu[i][value] == id) {
                name = menu[i][label];
                break
            } else if (menu[i][children] && menu[i][children].length > 0) {
                find(menu[i][children], id);  //递归调用
            }
        }
        return name;
    };
    return find(menu, id);
    /*for (let i = 0; i < menu.length; i++) {
      console.log(menu[i][value],menu[i][label]);
      if(menu[i][value]==id)return menu[i][label];
      if (menu[i][children] && menu[i][children].length != 0) {
        return findChildrenName(menu[i][children], id, props);
      }
    }*/
};

/**
 * 动态插入css
 */

export const loadStyle = url => {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = url;
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(link);
};
/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
    delete obj1.close;
    var o1 = obj1 instanceof Object;
    var o2 = obj2 instanceof Object;
    if (!o1 || !o2) { /*  判断不是对象  */
        return obj1 === obj2;
    }

    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
        //Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
    }

    for (var attr in obj1) {
        var t1 = obj1[attr] instanceof Object;
        var t2 = obj2[attr] instanceof Object;
        if (t1 && t2) {
            return diff(obj1[attr], obj2[attr]);
        } else if (obj1[attr] !== obj2[attr]) {
            return false;
        }
    }
    return true;
}
export const diffDetail = (obj1, obj2) => {
    delete obj1.close;
    var o1 = obj1 instanceof Object;
    var o2 = obj2 instanceof Object;
    if (!o1 || !o2) { /*  判断不是对象  */
        return obj1 === obj2;
    }
    if (!validatenull(obj1.value)) obj1.value = obj1.value.replace(/\/{(.*?)}/g, '');
    if (!validatenull(obj2.value)) obj2.value = obj2.value.replace(/\/{(.*?)}/g, '');
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
        //Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
    }

    for (var attr in obj1) {
        var t1 = obj1[attr] instanceof Object;
        var t2 = obj2[attr] instanceof Object;
        if (t1 && t2) {
            return diff(obj1[attr], obj2[attr]);
        } else if (obj1[attr] !== obj2[attr]) {
            return false;
        }
    }
    return true;
}
/**
 * 根据字典的value显示label
 */
export const findByvalue = (dic, value) => {
    let result = '';
    if (validatenull(dic)) return value;
    if (typeof (value) == 'string' || typeof (value) == 'number' || typeof (value) == 'boolean') {
        let index = 0;
        index = findArray(dic, value);
        if (index != -1) {
            result = dic[index].label;
        } else {
            result = value;
        }
    } else if (value instanceof Array) {
        result = [];
        let index = 0;
        value.forEach(ele => {
            index = findArray(dic, ele);
            if (index != -1) {
                result.push(dic[index].label);
            } else {
                result.push(value);
            }
        });
        result = result.toString();
    }
    return result;
};
/**
 * 根据字典的value查找对应的index
 */
export const findArray = (dic, value) => {
    for (let i = 0; i < dic.length; i++) {
        if (dic[i].value == value) {
            return i;
        }
    }
    return -1;
};
/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
    let random = '';
    random = Math.ceil(Math.random() * 100000000000000).toString().substr(0, len ? len : 4);
    if (date) random = random + Date.now();
    return random;
};
/**
 * 打开小窗口
 */
export const openWindow = (url, title, w, h) => {
    // Fixes dual-screen position                            Most browsers       Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

    const left = ((width / 2) - (w / 2)) + dualScreenLeft
    const top = ((height / 2) - (h / 2)) + dualScreenTop
    const newWindow = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left)

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus()
    }
}
/**
 * 获取浏览器链接的信息
 */
export const getUrlInfo = () => {
    if (window.location.href.split("?").length < 2) return {};
    const arr = (window.location.href.split("?")[1].split("&")), obj = {};
    arr.forEach((ele) => {
        obj[ele.split('=')[0]] = ele.split('=')[1];
    });
    return obj;
}
/**
 * 判断数组对象下的name是否有重复的
 */
export const isRepeatName = arr => {
    var hash = {};
    for (var i in arr) {
        if (hash[arr[i].name]) {
            return true;
        }
        hash[arr[i].name] = true;
    }
    return false;
}
/**
 * 将索引转换为大写字母
 */
export const indexToUpperLetter = index => {
    if (validatenull(index)) return '';
    return String.fromCharCode(index * 1 + 65);
}
export const upperLetterToIndex = str => {
    if (validatenull(str)) return '';
    return str.charCodeAt(0) - 65;
}

/**
 * 根目录导出模板文件
 */
export const downloadFileFix = (url, params = {}) => {
    let downLoadUrl = url;
    if (!validatenull(params)) downLoadUrl = downLoadUrl + '?' + serialize(params);
    const a = document.createElement('a');
    a.setAttribute('download', downLoadUrl);
    a.setAttribute('href', downLoadUrl);
    a.click();
}

/**
 * 将富文本编辑器的内容转化为纯文本
 */
export const htmlToText = (text) => {
    if (validatenull(text)) return '';
    return text.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ');
}
/**
 * 将字符串截取50位
 */
export const strLimit = (text = '') => {
    if (validatenull(text)) return '';
    return text.length > 30 ? text.slice(0, 30) + '...' : text;
}
/**
 * 获取浏览器信息宽高
 */
export const getScreenInfo = () => {
    return {
        W: document.body.clientWidth,
        H: document.body.clientHeight
    };
}
/**
 * 构建 element 级联选择器树形数据
 */

export const makeElementTree = (params) => {
    // 将参数拿出来，不喜欢 params.xxx 的调用方式
    const { pid, list, pidFiled, labelFiled, valueFiled } = params
    // 构建一个内部函数，用于实现递归
    const makeTree = (pid, arr) => {
        const res = []
        arr.forEach(i => {
            if (i[pidFiled] == pid) {
                // 自己调用自己，递归查归属于自己的 children
                const children = makeTree(i[valueFiled], list)
                // 将原有的数据按照 element 的格式进行重构
                const obj = {
                    label: i[labelFiled],
                    value: i[valueFiled]
                }
                // 如果有 children 则插入 obj 中
                if (children.length) {
                    obj.children = children
                }
                res.push(obj)
            }
        })
        return res
    }
    return makeTree(pid, list)
}


