<!--  -->
<template>

  <el-steps class="header_steps" :active="currentIndex" align-center>
    <el-step title="商户信息" v-if="!isShow"></el-step>
    <el-step title="填写公司信息"></el-step>
    <el-step title="品牌及意向信息"></el-step>
    <el-step title="提交审核"></el-step>
  </el-steps>
</template>

<script>
export default {
  props: {
    activeIndex: {
      type: Number,
      default: 1
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    };
  },
  computed: {
    currentIndex () {
      return this.isShow ? (+this.activeIndex - 1) : this.activeIndex
    }
  }
}

</script>
<style lang='less' scoped>
</style>