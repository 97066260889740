<template>
  <div class="wrap">
    <div class="content">
      <h2 style="font-size: 20px;">提交审核</h2>

      <el-card class="header">
        <Steps :active-index="4" :is-show="merId !== ''"/>
      </el-card>
      <el-form
        ref="form"
        size="small"
        :model="form"
        :rules="rules"
        :labelWidth="labelWidth"
        label-position="right"
      >
        <el-card class="merchant_card" v-if="merId === ''">
          <p class="title">商户信息</p>
          <el-divider class="divider"></el-divider>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="商户名称:" prop="merName">
                {{ form.merName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系人:" prop="linkmanName">
                {{ form.linkmanName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号:" prop="linkmanPhone">
                {{ form.linkmanPhone }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系邮箱:" prop="linkmanMail">
                {{ form.linkmanMail }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="merchant_card">
          <p class="title">公司信息</p>
          <el-divider class="divider"></el-divider>
          <!-- 公司基本信息 -->
          <el-divider content-position="left">公司基本信息</el-divider>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="公司名称:" prop="name">
                <span>{{ form.name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统一社会信用代码:" prop="creditCode">
                <span>{{ form.creditCode }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公司性质:" prop="nature">
                <!-- <span>{{ form.type }}</span> -->
                <!-- <span>{{companyNatureList}}</span> -->
                <p v-for="item in companyNatureList" :key="item.id">
                  <span v-if="item.dictValue == form.nature">{{item.dictLabel}}</span>
                </p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话:" prop="coLinkmanPhone">
                <span>{{ form.coLinkmanPhone }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="公司注册地址:" prop="coRegAddress">
                <span>{{ form.coRegAddrAll }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="开票信息地址:" prop="ticketAddress">
                <span>{{ form.ticketAddrAll }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系邮箱:" prop="coLinkmanMail">
                <span>{{ form.coLinkmanMail }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮政编码:" prop="postCode">
                <span>{{ form.postCode }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="纳税人标识:" prop="taxPayerFlag">
                <span v-if="form.taxPayerFlag == 0">一般纳税人</span>
                <span v-if="form.taxPayerFlag == 1">小规模纳税人</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="法人姓名:" prop="legalName">
                <span>{{ form.legalName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="法人手机号:" prop="legalPhone">
                <span>{{ form.legalPhone }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 银行信息 -->
          <el-divider content-position="left">银行信息</el-divider>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="支行名称:" prop="bankDesc">
                <span>{{ form.bankDesc }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="银行账号:" prop="bankCode">
                <span>{{ form.bankCode }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="银行账户持有人:" prop="bankCountMaster">
                <span>{{ form.bankCountMaster }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="开户行地址:" prop="bankAddress">
                {{ form.bankAddr }}
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="对公对私:" prop="selfOrAll">
                {{ parseInt(form.selfOrAll) == 1 ? '对私' : '对公' }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="left">公司营业执照</el-divider>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="有效期限类型:" prop="licDeadlineType">
                {{ form.licDeadlineType == 1 ? '永久' : '有限' }}
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="营业执照有效日期:" v-if="form.licDeadlineType == 2" prop="licDeadlineDate">
                {{ form.licDeadlineDate }}
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="营业执照上传:" prop="licResUrl">
                <el-image
                  v-if="licResUrl"
                  style="width: 100px; height: 100px"
                  :src="licResUrl"
                  fit="contain"
                ></el-image>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="left">法定代表人身份证正反面</el-divider>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="身份证号:" prop="legalIdCode">
                <span>{{ form.legalIdCode }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="身份证正面上传:" prop="cardJustUrl">
                <el-image
                  v-if="cardJustUrl"
                  style="width: 100px; height: 100px"
                  :src="cardJustUrl"
                  fit="contain"
                ></el-image>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="身份证反面上传:" prop="cardBackUrl">
                <el-image
                  v-if="cardBackUrl"
                  style="width: 100px; height: 100px"
                  :src="cardBackUrl"
                  fit="contain"
                ></el-image>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
        <!-- 品牌意向信息 -->
        <el-card class="merchant_card">
          <p class="title">品牌及意向信息</p>
          <el-divider class="divider"></el-divider>
          <el-divider content-position="left">意向信息</el-divider>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="意向城市:">
                <span>{{ form.cooperateCity }}</span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="入驻模式:" prop="entryType">
                <span v-if="form.entryType == 1">线上</span>
                <span v-if="form.entryType == 2">线下</span>
                <span v-if="form.entryType == 3">融合</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-tabs v-model="currentTab" type="card">
            <!-- 获取数据循环 -->
            <el-tab-pane
              v-for="(item, index) in form.entryBrandCaUpdateVos"
              :key="index"
              :label="item.zhName"
              :name="item.name"
            >
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="品牌名称:" prop="zhName">
                    <span>{{ item.zhName }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="品牌级别:" prop="popLevel">
                    <span v-if="item.popLevel == 0">国际品牌</span>
                    <span v-if="item.popLevel == 1">国内连锁品牌</span>
                    <span v-if="item.popLevel == 2">国内区域品牌</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="品牌LOGO:">
                    <el-image
                      v-if="item.relativeURL && item.relativeURL !== ''"
                      style="width: 100px; height: 100px"
                      :src="item.relativeURL"
                      :preview-src-list="[item.relativeURL]"
                      fit="contain"
                    >
                    <div slot="error" class="image-slot">
                    </div>
                    </el-image>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="品牌业态:">
                    <el-cascader
                      disabled
                      v-model="item.caId"
                      :options="treeCats"
                      :props="props"
                    ></el-cascader>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="商标证:" prop="trademarkList">
                    <el-image
                      v-if="item.trademarkUrl && item.trademarkUrl !== ''"
                      style="width: 100px; height: 100px"
                      :src="item.trademarkUrl"
                      :preview-src-list="[item.trademarkUrl]"
                      fit="contain">
                    <div slot="error" class="image-slot">
                    </div>
                    </el-image>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="商品源原产地:" prop="goodOrigin">
                    <span v-if="item.goodOrigin == 1">国产</span>
                    <span v-if="item.goodOrigin == 2">进口</span>
                    <span></span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="品牌方是否为个人:" prop="brandIsPersonal">
                    <span v-if="item.brandIsPersonal == 0">否</span>
                    <span v-if="item.brandIsPersonal == 1">是</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    label="品牌授权方是否是境内公司:"
                    prop="brandAuthIsDomestic"
                  >
                    <span v-if="item.brandAuthIsDomestic == 0">否</span>
                    <span v-if="item.brandAuthIsDomestic == 1">是</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider content-position="left">经营资质</el-divider>
              <el-table
                border
                stripe
                :header-cell-style="{backgroundColor: 'rgb(243, 243, 243)', color: 'rgb(102, 102, 102)'}"
                :data="applyForTableData"
                style="width: 100%; margin-bottom: 10px;">
                <el-table-column prop="status" label="经营资质列表">
                </el-table-column>
                <el-table-column prop="desc" label="备注">
                </el-table-column>
              </el-table>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="资质图片上传:" prop="company">
                    <!-- 多张图片循环template -->
                    <template
                      v-for="(
                        subItem, index
                      ) in item.trademarkAuthQualificationsURL"
                    >
                      <el-image
                        :key="index"
                        style="width: 100px; height: 100px"
                        :src="subItem"
                        :preview-src-list="[subItem]"
                        fit="fill"
                      ></el-image>
                    </template>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </el-card>
        <!-- 审核信息 -->
        <el-card class="merchant_card" v-if="form.auditList && form.auditList.length > 0">
          <el-table :data="form.auditList" stripe style="width: 100%">
            <el-table-column label="序号" type="index" width="180"></el-table-column>
            <el-table-column label="节点" prop="nodeName" width="180"></el-table-column>
            <el-table-column label="审核人" prop="approvalPersonName" width="180"></el-table-column>
            <el-table-column label="审核时间" prop="createTime"></el-table-column>
            <el-table-column label="审核结果" prop="resultName"></el-table-column>
            <el-table-column label="审核意见" prop="opinion"></el-table-column>
          </el-table>
        </el-card>
      </el-form>
      <div class="btn-group">
        <el-button class="btn" size="small" v-if="updateStatus !== 1" @click="handleBack">返回</el-button>
        <el-button class="btn" v-if="updateStatus === 1" size="small" @click="handleBack1">上一步</el-button>
        <el-button class="btn" type="primary" v-if="form.status === '2'" size="small" @click="handleNew">再次申请</el-button>
        <el-button type="primary" class="btn" v-if="form.status === '9' && updateStatus !== 1" size="small" @click="handleUpdate">修改</el-button>
        <el-button
          class="btn"
          size="small"
          type="primary"
          style="width: 180px;"
          :loading="loading"
          v-if="(!form.status) || updateStatus === 1"
          @click="handleSubmit"
        >确认无误提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getUploadFile, getCaByLevel, setApply, updateApply, getBrandImageId, getByBusList, getApplyInfo } from "@/api";
import { makeElementTree } from "@/utils/util";
import { mapState, mapGetters } from "vuex";

import Steps from "@/components/Steps";

export default {
  components: {
    Steps,
  },
  // props: [],
  data() {
    return {
      merId: '',
      // 表单宽度
      labelWidth: "190px",
      loading: false,
      // 表单数据
      form: {},
      currentTab: 0, // tabs数据
      licResPath: "", // 营业执照
      cardJustPath: "", // 身份证正面
      cardBackPath: "", // 身份证反面
      auditList: [], // 审核信息表格数据
      tableData: [], //
      rules: {},
      licResUrl: "",
      cardJustUrl: "",
      cardBackUrl: "",
      treeCats: [],
      props: {
        multiple: false,
        label: "label",
        value: "value",
        children: "children",
      },
      applyForTableData: [
        {
          status: "商标注册证/商品注册申请受理通知书",
          desc:
            "由国家商标总局颁发的商标注册证（R标）或商标注册申请受理通知书（TM标），可接受商标局查询截图，需加盖公司红章。若商标发生过变更，需上传变更证明。扫描原件或复印件加盖入驻公司红章。",
        },
        {
          status: "其他资质",
          desc:
            "需提交特殊产品资质的类目请将资质上传在此处，包括全国工业生产许可证、3C、医疗器械生产许可证、化妆品生产企业卫生许可证等，根据具体类目资质要求提交",
        },
      ],
    };
  },
  computed: {
    ...mapState(['updateStatus']),
    ...mapGetters(["companyNatureList", 'approvalType']),
    status () {
      return this.form.status || ''
    }
  },
  watch: {},
  async created() {
    const info = JSON.parse(localStorage.getItem("merchant_info1")) || {};
    const merchantInfo =
      JSON.parse(localStorage.getItem("merchant_info")) || {};
    
    const options = {
      coRegAddrAll: merchantInfo.coRegAddrDetail ? `${merchantInfo.coRegAddr}${merchantInfo.coRegAddrDetail}` : merchantInfo.coRegAddr, // 商户住所
      ticketAddrAll: merchantInfo.ticketAddrDetail ? `${merchantInfo.ticketAddr}${merchantInfo.ticketAddrDetail}` : merchantInfo.ticketAddr, // 开票信息地址
    }
    this.form = {
      ...this.form,
      ...merchantInfo,
      ...info,
      ...options
    };
    this.merId = this.form.merId
    let res = await getCaByLevel();
    const treeCats = makeElementTree({
      pid: null, // 顶级分类的 pid 为 null
      list: res.data.data, // 将原始数组参数传进去
      pidFiled: "parentId", // 在pid 字段名为 parentId
      labelFiled: "name", //  label 字段名为 name
      valueFiled: "caId", // value 字段名为 caId
    });
    this.treeCats = treeCats;
    this.$store.dispatch("getAllDictionaryList")

    if (merchantInfo.licResIdList && merchantInfo.licResIdList.length) {
      getUploadFile(merchantInfo.licResIdList[0]).then((response) => {
        this.licResUrl = response.data.data.res.url;
      });
    }
    if (merchantInfo.cardJustList && merchantInfo.cardJustList.length) {
      getUploadFile(merchantInfo.cardJustList[0]).then((response) => {
        this.cardJustUrl = response.data.data.res.url;
      });
    }
    if (merchantInfo.cardBackList && merchantInfo.cardBackList.length) {
      getUploadFile(merchantInfo.cardBackList[0]).then((response) => {
        this.cardBackUrl = response.data.data.res.url;
      });
    }
    if (this.form.entryId) {
      const res2 = await getByBusList(this.form.entryId)
      let resApply = await getApplyInfo(this.form.entryId)
      resApply = resApply.data.data
      const list = this.getCurrentApply(res2.data.data)
      if (res2.data.code === 0) {
        this.form = {
          ...this.form,
          status: resApply.status,
          auditList: list
        }
      }
    }
    if (this.form.entryBrandCaUpdateVos && this.form.entryBrandCaUpdateVos.length) {
      for (var i = 0; i < this.form.entryBrandCaUpdateVos.length; i++) {
        // 获取品牌LOGO地址
        const brandId = this.form.entryBrandCaUpdateVos[i].brandId
        if (brandId) {
          let res = await getBrandImageId(brandId)
          const logoResId = res.data.data.logoResId
          if (logoResId) {
            res = await getUploadFile(logoResId)
            // this.form.entryBrandCaUpdateVos[i].relativeURL = res.data.data.res.url
            this.$set(this.form.entryBrandCaUpdateVos[i], 'relativeURL', res.data.data.res.url)
          }
        }
        
        // 获取资质图片
        const list = this.form.entryBrandCaUpdateVos[i].trademarkAuthQualifications
        if (list && list.length > 0) {
          this.$set(this.form.entryBrandCaUpdateVos[i], 'trademarkAuthQualificationsURL', [])
          for (var x = 0; x < list.length; x++) {
            let res = await getUploadFile(list[x].taqImagePath)
            this.$set(this.form.entryBrandCaUpdateVos[i].trademarkAuthQualificationsURL, x, res.data.data.res.url)
          }
        }

        // 获取商标证图片地址
        const path = this.form.entryBrandCaUpdateVos[i].trademarkPath
        if (path && path.length > 0) {
          let res = await getUploadFile(path[0])
          this.$set(this.form.entryBrandCaUpdateVos[i], 'trademarkUrl', res.data.data.res.url)
        }
      }
    }
  },
  beforeDestroy () {
    this.$store.commit('SET_UPDATE_STATUS', 0)
  },
  methods: {
    getCurrentApply (list) {
      if (list.length === 0) return []
      if (list.length === 1) return list
      list.sort(function(a, b) {
        return b.createTime > a.createTime ? -1 : 1
      })
      return list
    },
    // 选择品牌
    selectBrand() {
      this.brandDialog = true;
    },
    // 返回
    handleBack() {
      this.$router.push('mallGuide')
    },
    handleBack1 () {
      this.$router.push("brandInfo");
    },
    handleNew () {
      const initData = JSON.parse(localStorage.getItem('merchant_info1')) || {}
      localStorage.removeItem('merchant_info')
      localStorage.setItem('merchant_info', JSON.stringify(initData))
      this.$router.push('companyInfo')
    },
    handleUpdate () {
      this.$router.push('companyInfo')
    },
    // 提交
    async handleSubmit() {
      let resApply = await getApplyInfo(this.form.entryId)
      resApply = resApply.data.data

      if (resApply.status && resApply.status === '2') {
        this.$message({
          showClose: true,
          message: '审核已通过，请重新加载页面',
          type: 'success',
          duration: 2000,
        })
        return
      }
      this.form.entryBrandCaUpdateVos.forEach(item => {
        if (item.trademarkAuthQualificationsList && item.trademarkAuthQualificationsList.length) {
          item.trademarkAuthQualifications = []
          item.caId = (typeof item.caId === 'string' ? item.caId : item.caId[item.caId.length - 1])
          item.trademarkPath = (item.trademarkPath && item.trademarkPath[0]) ? JSON.stringify(item.trademarkPath[0]) : ''
          item.trademarkAuthQualificationsList.forEach((subItem, index) => {
            item.trademarkAuthQualifications[index] = { taqImagePath: subItem }
          })
        }
      })
      this.loading = true
      let res = {}
      if (this.form.status === '9') {
        res = await updateApply(this.form)
      } else {
        res = await setApply(this.form)
      }
      
      if ((res.data && res.data.code === 0) || res.code === 0) {
        this.loading = false
        setTimeout(() => {
          this.$store.commit('SET_UPDATE_STATUS', 0)
        }, 2500)
        this.$message({
          showClose: true,
          message: '提交成功!',
          type: 'success',
          duration: 2000,
          onClose: () => {
            localStorage.clear()
            this.$router.push('index')
          }
        });
      } else {
        this.loading = false
        this.$message({
          showClose: true,
          duration: 3000,
          message: res.data.message,
          type: 'warning'
        });
      }
      
    },
    getType (val) {
      if (this.approvalType && this.approvalType.length > 0) {
        const obj = this.approvalType.find(item => item.dictValue === val)
        return obj.dictLabel
      }
      return ''
    },
    handleBackPage (num) {
      if (this.form.status != 1) {
        this.$store.commit('SET_UPDATE_STATUS', 1)
        if (num === 1) {
          this.$router.push('companyInfo')
        } else if (num === 2) {
          this.$router.push('brandInfo')
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
p {
  margin: 0;
  padding: 0;
}
.wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #edf3fe;
  padding-top: 60px;
  // 步骤条区域
  .header {
    height: 164px;
    margin-bottom: 1.9%;
    margin-top: 20px;
    padding-top: 40px;
    box-sizing: border-box;
  }
  .content {
    width: 80%;
    min-height: 500px;
    margin: 40px auto;
  }
}
.title {
  font-size: 18px;
  margin: 0;
}
.divider {
  margin: 8px 0 24px 0;
}
.merchant_card {
  width: 100%;
  margin-bottom: 20px;
}
.btn-group {
  display: flex;
  justify-content: center;
  margin: 20px;

  .btn {
    width: 124px;
    height: 40px;
    border-radius: 5px;
    margin: 0 20px;
    font-size: 18px;
  }
}
</style>
